footer {
    position: relative;

    // Estilos aplicados para dispositivos menores a md
    @include media-breakpoint-down(xl) {
        background: white; // Fondo Coral Rojo
        border-top: 15px solid #e6473b; // Borde inferior
    }

    // Estilos aplicados para dispositivos md en adelante
    @include media-breakpoint-up(xl) {
        background-image: linear-gradient(to right, #e6473b 60%, white 40%);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60%;
            height: 18px;
            background-color: #686363;
        }

        .container {
            background-image: url('/assets/img/fondo-pie-pagina-sercol.svg');
            background-position: right 30% bottom;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
