// Colors
$primary: #e43b2f;
$btn-color: white;
$primary-dark: #a01b16;
$secondary: #f88379;
$black: #2f2f41;
$white: #ffffff;
$white-offset: #fff6f8;
$steel: #5c5a5a;
$coral-red: #e6473b;

// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: lighten($primary, 10%);
$link-hover-decoration: underline;

// Fonts
$font-family-base: "Montserrat", serif, -apple-system;

// Footer
$footer-background-color: $primary;
$footer-text-color: $white;
$sub-footer-background-color: darken($primary, 10%);
$sub-footer-text-color: $white;

// Forms
$form-label-font-weight: 600;
$input-border-color: #707070;

// Settings
$enable-negative-margins: true;


// Bootstrap 5.3.2

@import "bootstrap/functions";

@import "bootstrap/variables";
@import "bootstrap/variables-dark";


$font-sizes: map-merge($font-sizes,
  (7: $font-size-base * 0.875, // 14px
    8: $font-size-base * 0.75, // 12px
  ));
$theme-colors: map-merge($theme-colors,("coral-red": $coral-red));


@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/utilities";
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/carousel";

// 5. Include Bootstrap helpers and utilities
@import "bootstrap/helpers";
@import "bootstrap/utilities/api";

// Components
@import "components/navbar";
@import "components/footer";

body {
  font-size: 16px;
  line-height: 1.2;
  font-family: $font-family-base;
  height: 100vh;
  margin: 0;
  padding: 0;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 1.3;
  }
}

// Carousel
.carousel-indicators {
  .active {
    background-color: $primary;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
}

.text-shadow {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}

.btn-shadow {
  box-shadow: 1px 1px 18px 0px rgba(0,0,0,0.6);
}